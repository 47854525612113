<template>
  <div class="flightbooking_stickyfooter">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <p>{{$t("sabre.booking.total-payment")}} <strong>${{totalPrice}}</strong>
        </p>
        <a href="#" class="btn btn-outline-primary">{{$t("sabre.booking.view-details")}}</a>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  name: 'mobile-footer',
  computed: {
    ...mapGetters({
      flight: 'GET_SABRE_ORDERED_FLIGHT',
    }),
    totalPrice() {
      const { flight } = this;
      return Math.round(flight.excerpt.totalPrice);
    },
  },

};
</script>
